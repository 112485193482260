<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="page-wrapper login">
      <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
          <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
            <div class="d-flex align-items-center container p-0">
              <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                <a href="javascript:void(0)"
                   class="page-logo-link press-scale-down d-flex align-items-center">
                  <img src="media/logo.svg" class="logo-edutalk" alt="Edutalk Tư Vấn"
                       aria-roledescription="logo">
                  <span class="page-logo-text mr-1"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 ml-auto">
                  <div class="card p-4 rounded-plus bg-faded">
                    <h1 class="fw-400 mb-3 d-sm-block">
                      Quên mật khẩu
                    </h1>
                    <form id="js-login" class="form" @submit.prevent="handleSubmit(onSubmit)">
                      <div class="form-group">
                        <label class="form-label email-content mb-3">Nhập email bạn đã đăng ký. Chúng tôi sẽ gửi cho bạn một mã xác nhận để đặt lại mật khẩu.</label>
                        <ValidationProvider vid="email" name="Email" rules="required"
                                            v-slot="{ errors, classes }">
                          <input type="email" ref="email" name="email" class="form-control form-control-lg"
                                 :class="classes"
                                 placeholder="Nhập email bạn đã đăng ký"
                                 v-model=form.email>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-lg-12 pr-lg-1 my-2">
                          <button type="submit" id="login-submit" ref="login-submit"
                                  class="btn btn-primary btn-block btn-lg waves-effect waves-themed" :disabled="isLoading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="isLoading"></span>
                            Tiếp tục
                          </button>
                        </div>
                        <div class="col-lg-12 mt-3 text-center justify-content-edutalk ">
                          <router-link to="/login" v-slot="{ href, navigate }">
                            <a :href="href"  title="Đăng ký tài khoản"
                               @click="navigate"
                               data-filter-tags="Đăng ký tài khoản"
                               class=" waves-effect waves-themed">
                              Quay lại đăng nhập
                            </a>
                          </router-link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col col-md-6 col-lg-7 hidden-sm-down">
                  <lottie-animation :path="'media/lottie/english-teacher.json'" />
                </div>
              </div>
              <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                          target="_blank">edutalk.edu.vn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<!-- Load login custom page styles -->

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import {FORGOT_PASSWORD} from "@/core/services/store/auth.module";
export default {
  name: "edutalk-forgot-password",
  components: {
    LottieAnimation
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      state: "signin",
      isLoading : false,
      form: {
        email: "",
      },
      check_error: false
    };
  },
  mounted() {

  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      var email = this.form.email;
      this.$store.dispatch(FORGOT_PASSWORD, {email : email}).then((res) => {
        this.$router.push({name: 'otp'});
      }).catch((res) => {
        if (res.data?.message_validate_form){
          this.$refs.form.setErrors(res.data.data?.message_validate_form);
        }else {
          this.$refs.form.setErrors(res.data.data);
        }
        this.isLoading = false;
      })

    },

  }
};
</script>
<style>
.email-content {
  color: #747474;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
</style>
